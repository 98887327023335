import _ from 'lodash';
import {ICartStyleSettings} from '../../types/app.types';
import {StyleParam} from './constants';

export function getCartSettingsFromStyles(rawStyles: ICartStyleSettings): ICartStyleSettings {
  const defaultStyles: ICartStyleSettings = {
    booleans: {
      [StyleParam.ShowContinueShopping]: false,
      [StyleParam.ShowBuyerNote]: true,
      [StyleParam.ShowTax]: false,
      [StyleParam.ShowShipping]: true,
      [StyleParam.Responsive]: false,
    },
    fonts: {
      [StyleParam.CornerRadius]: undefined,
      [StyleParam.SelectedSkin]: undefined,
    },
  };

  return _.merge({}, defaultStyles, rawStyles);
}
